import { GridApi, GridReadyEvent } from 'ag-grid'
import 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'
import { Formik } from 'formik'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Dropdown, Form, Label } from 'semantic-ui-react'
import { FF, FormikCheck, FormikDate, FormikInput, FormikSelect } from '../../components/form/FormInputs'
import UnitsSelect from '../../components/form/UnitsSelect'
import { MAILING_STATUS } from '../../fields'
import { useLocalStorageTS } from '../../hooks'
import { grid_refs_actions } from '../../redux/modules/grid-refs'
import { dateTimeFormat } from '../../utils/Formatters'
import { gridDefaults } from '../../utils/gridDefaults'

const MailingGrid = (props: { componentParent: any }) => {
  const pagination_size = 50
  const initial_values = {
    unit: '',
    status: 1,
    relative_start_date: 2,
    pick_start_date: moment().subtract(2, 'days'),
    end_date: '',
    from_to: ''
  }

  const dispatch = useDispatch()
  const [gridApi, setGridApi] = useState<GridApi | null>(null)
  const [filter_values, setFilterValues] = useLocalStorageTS<any>('filter_grid_mailing', initial_values)

  useEffect(() => {
    return () => setFilterValues(initial_values)
  }, [])

  const prepareFilterQs = () => {
    let filter_qs_state = JSON.parse(localStorage.getItem('filter_grid_mailing') ?? '{}') ?? {}

    if (filter_qs_state._pick_date && filter_qs_state.pick_start_date) {
      filter_qs_state['start_date'] = moment(filter_qs_state.pick_start_date).format('YYYY-MM-DD')
    } else {
      filter_qs_state['end_date'] = ''
      filter_qs_state['start_date'] = moment().subtract(filter_qs_state.relative_start_date, 'days').format('YYYY-MM-DD')
    }

    return filter_qs_state
  }

  const serverSideDatasource = gridDefaults.makeServerSideDataSource('admin/mailings/gater_list', pagination_size, prepareFilterQs)

  const GRID = {
    gridOptions: {
      ...gridDefaults.adminGrid,
      context: {
        componentParent: props.componentParent
      },
      rowHeight: 70,
      cacheBlockSize: pagination_size,
      paginationPageSize: pagination_size,
      serverSideDatasource: serverSideDatasource,
      rowModelType: 'serverSide',
      rowSelection: 'multiple',
    },
    columnDefs: [
      {
        width: 30,
        checkboxSelection: true,
        resizable: false,
        suppressMovable: true,
        suppressMenu: true,
        suppressSizeToFit: true,

        sortable: false,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true
      },
      {
        colId: 'unit',
        field: 'unit.with_block',
        headerName: 'Unidade/Status',
        width: 55,
        filter: 'text',
        cellRendererFramework: (params) => {
          if (!params.node.data) return null

          let value = params.getValue()
          let status_value = MAILING_STATUS[params.node.data.status]

          return (
            <div>
              {value || 'Não definido '} <br/>
              {status_value === 'Entregue' ?
                <Label color="green" className="grid-label">{status_value}</Label> :
                <Label color="yellow" className="grid-label">{status_value}</Label>}
            </div>
          )
        }
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 30,
        filter: 'text',
        hide: true,
        valueGetter: (params) => MAILING_STATUS[params.data.status]
      },
      {
        colId: 'from_to',
        headerName: 'Remetente/Destinatário',
        width: 140,
        filter: 'text',
        cellRendererFramework: (params) => {
          if (!params.node.data) return null

          return (
            <div>
              Remetente: {params.data.from_field} <br/>
              Destinatário: {params.data.to}
            </div>
          )
        }
      },
      {
        colId: 'start_date',
        headerName: 'Data',
        width: 70,
        filter: 'text',
        cellRendererFramework: (params) => {
          if (!params.node.data) return null

          return (
            <div>
              Criado: {dateTimeFormat(params, 'created_at')} <br/>
              Retirado: {dateTimeFormat(params, 'delivered_at')}
            </div>
          )
        }
      },
      {
        headerName: 'Ações',
        width: 210,
        suppressResize: true,
        suppressMovable: true,
        suppressSorting: true,
        suppressMenu: true,
        suppressFilter: true,
        suppressSizeToFit: true,
        cellClass: 'ag-button-cell',
        cellRendererFramework: (params) => {
          if (!params.node.data) return null

          return (
            <>
              {params.data && params.data.status === 1 &&
                <Button primary compact onClick={() => {
                  params.context.componentParent.handleOpen(params.data)
                }}>
                  RETIRAR
                </Button>}

              <Button.Group basic primary compact>
                <Dropdown
                  text="Ações  "
                  icon="pencil"
                  floating
                  button
                  className="icon"
                >
                  <Dropdown.Menu>
                    <Dropdown.Header icon="tags" content="Selecione uma Ação"/>
                    <Dropdown.Divider/>

                    <Dropdown.Item onClick={() => {
                      params.context.componentParent.handleOpenDetails(params.data)
                    }}>
                      Detalhes
                    </Dropdown.Item>

                    <Dropdown.Item onClick={() => {
                      params.context.componentParent.handleOpenChangeUnit(params.data)
                    }}>
                      Alterar Unidade
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Button.Group>
            </>
          )
        }
      }
    ],
    onGridReady: (params: GridReadyEvent) => {
      setGridApi(params.api)
      dispatch(grid_refs_actions.update_ref({ key: 'mailing', ref: params.api }))
    }
  }

  return (
    <React.Fragment>
      <div className="ui segment page-filter-container">
        <Formik
          initialValues={{
            unit: filter_values.unit,
            status: filter_values.status,
            relative_start_date: 2,
            pick_start_date: filter_values.pick_start_date,
            end_date: filter_values.end_date,
            from_to: filter_values.from_to,
            _pick_date: false
          }}
          validate={values => {
            let errors: any = {}

            if (values._pick_date) {
              if (!values.pick_start_date) {
                errors.pick_start_date = 'Selecione uma data'
              }

              let qtd_days = moment(values.end_date).isValid() ?
                moment(values.end_date).diff(values.pick_start_date, 'days') :
                moment().diff(values.pick_start_date, 'days')

              if (qtd_days > 132) errors.pick_start_date = 'O intervalo de datas não pode exceder 120 dias! ' +
                'Selecione uma data no campo "DATA (FIM)", ou diminua o intervalo.'
            } else {
              if (!values.relative_start_date) {
                errors.relative_start_date = 'Selecione uma data'
              }
            }

            return errors
          }}
          onSubmit={(values) => {
            if (!values._pick_date) {
              values['end_date'] = ''
            }

            setFilterValues(values)
            gridApi?.onFilterChanged()
          }}
          render={({ submitForm, values }) => (
            <Form>
              <FF label={'Selecionar data'} name="_pick_date" component={FormikCheck}/>

              <Form.Group widths="equal" style={{ margin: 0 }}>
                <UnitsSelect name="unit" label="Unidade"/>
                <FF name="status" label="Status" component={FormikSelect} local_options={MAILING_STATUS}/>

                {values._pick_date ?
                  <>
                    <FF name="pick_start_date" label="Data (Início)" component={FormikDate}/>
                    <FF name="end_date" label="Data (Fim)" component={FormikDate}/>
                  </> :
                  <FF name="relative_start_date" label="Data" component={FormikSelect}
                      options={
                        [
                          { value: 2, text: '2 dias atrás' },
                          { value: 7, text: '7 dias atrás' },
                          { value: 15, text: '15 dias atrás' },
                          { value: 30, text: '30 dias atrás' },
                          { value: 60, text: '60 dias atrás' }
                        ]
                      }
                  />}

                <FF name="from_to" label="Remetente/Destinatário" component={FormikInput}/>

                <Button primary size="small" basic content="Filtrar" onClick={submitForm}/>
              </Form.Group>
            </Form>
          )}
        />
      </div>

      <div className="ui raised segment page-grid-container">
        <div style={{ width: '100%' }} className="ag-theme-material">
          <AgGridReact
            gridOptions={GRID.gridOptions}
            columnDefs={GRID.columnDefs}
            onGridReady={GRID.onGridReady}
            onSelectionChanged={(event) => {
              let selected = gridApi.getSelectedRows()
              props.componentParent.setState({ selected_rows: selected })
            }}
          />
        </div>
        <div className="ui divider hidden" style={{ clear: 'both', margin: 0 }}/>
      </div>
      <div className="ui divider hidden" style={{ clear: 'both', margin: 0 }}/>
    </React.Fragment>
  )
}

export default MailingGrid