import { ExitToApp } from 'material-ui-icons'
import AppBar from 'material-ui/AppBar'
import Badge from 'material-ui/Badge'
import Divider from 'material-ui/Divider'
import IconButton from 'material-ui/IconButton'
import IconMenu from 'material-ui/IconMenu'
import { ListItem } from 'material-ui/List'
import MenuItem from 'material-ui/MenuItem'
import { white } from 'material-ui/styles/colors'
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert'
import NotificationsIcon from 'material-ui/svg-icons/social/notifications'
import moment from 'moment/moment'
import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Icon, Label, Popup, Table } from 'semantic-ui-react'
import LiveTable from '../containers/Live/LiveTable'
import { useLocalServerSettingsQuery, useMeQuery } from '../hooks'
import { qs } from '../queries'
import { history, store } from '../redux/store/configureStore'
import { iotAuthenticate, iotClient } from '../services/awsIoT'
import { localPostApi, localPostApi_ValidateLocalServer } from '../utils/LocalApi'
import { LocalDB } from '../utils/LocalDB'
import { connect_local_ws } from '../utils/WSLocalService'
import { hasPerm } from './acl'
import AccessButtonTable from './devices/AccessButtonTable'
import { LicenseHeader } from './LicenseHeader'
import ServerServicePopup from './ServerServicePopup'
import { IntercomHeader, IntercomNotifications } from '../containers/Live/Caller'
import { useCallStore } from '../redux/store/useCallStore'
import { ChatButtonMenu } from '../containers/Chat'
import { UserWebsocketHandler } from './UserWebsocketHandler'

const style = {
  appBar: {
    position: 'fixed',
    top: 0,
    overflow: 'hidden',
    maxHeight: 57
  },
  menuButton: {
    marginLeft: 10
  },
  tags: {
    marginBottom: 15
  },
  iconsRightContainer: {
    marginLeft: 20
  },
  header: {
    paddingLeft: 205,
    position: 'fixed',
    top: 0,
    overflow: 'hidden',
    maxHeight: 57,
    'MsOverflowStyle': 'scrollbar',
    'WebkitAppRegion': 'drag'
  }
}

const Header = () => {
  const { data: me } = useMeQuery()
  const { data: settings } = useLocalServerSettingsQuery()

  const call_state = useCallStore((state) => state.call)
  const updateCall = useCallStore((state) => state.updateCall)

  const visits = useSelector((state: any) => state.visits.data)
  const guarita_status = useSelector((state: any) => state.devices.guarita_status)

  const notificationQuery = useQuery<any>(
    'meNotifications',
    () => qs.user.notifications(),
    {
      enabled: false,
      retry: false,
      cacheTime: 1000 * 60 * 5,
      staleTime: 1000 * 60 * 5
    }
  )
  const notificationReadMeQuery = useQuery(
    'getReadMeAllNotifications',
    () => qs.user.read_all_notifications(),
    {
      enabled: false,
      retry: false,
      onSuccess: () => {
        notificationQuery.refetch()
      }
    })

  const liveNotificationsKeyQuery = useQuery(
    'getLiveNotificationsKey',
    () => qs.user.liveNotificationsKey(),
    {
      enabled: false,
      onSuccess: (_res) => {
        console.log('_res', _res)
        updateCall({
          call_notifications_token: '',
          server_url: _res.server_url
        })

        setTimeout(() => {
          updateCall({
            call_notifications_token: _res.token,
            server_url: _res.server_url
          })

        }, 2000)
      }
    })

  useEffect(() => {
    notificationQuery.refetch()
    liveNotificationsKeyQuery.refetch()
    connect_local_ws(store)

    iotHandle()

    setInterval(() => {
      liveNotificationsKeyQuery.refetch()
    }, 1000 * 60 * 2)

  }, [])

  useEffect(() => {
    if (settings?.anviz__status === '1') {
      // ServerAnviz.events_listener()
    }

    if (settings?.guarita__status === '1') {
      setTimeout(() => {
        localPostApi_ValidateLocalServer('linear/read_events_progressive')
      }, 4000)

      setInterval(() => {
        localPostApi_ValidateLocalServer('linear/listen_guarita_status')
      }, 20000)
    }

    let counters = [45000, 65000]
    let timeout = counters[Math.floor(Math.random() * counters.length)]

    const inter1 = setInterval(() => {
      localPostApi_ValidateLocalServer('utils/websocket_keepalive')
    }, timeout)

    return () => {
      clearInterval(inter1)
    }
  }, [settings])

  const notificationHandler = (item: any) => {
    if (item.action_object_content_type_name === 'ticket' && hasPerm(['ticket.view_ticket'])) {
      history.push('/admin/tickets')
    }
  }

  const iotHandle = async () => {
    const user: any = LocalDB.getItem('current_user')

    let client = await iotClient()

    client.on('connect', () => {
      client.subscribe(`admin-user-${user.team.schema_name}-${user.id}`)
    })

    client.on('error', (err) => {
      console.error('\n\n\n\n\n')
      console.error('IOT ERRO ->>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
      console.error('\n')
      iotAuthenticate(client)
      iotAuthenticate()
      console.error(err)
      console.error('\n\n\n\n\n')
    })

    client.on('message', (topic, payload) => {
      const msg = JSON.parse(payload.toString())
      console.log('mqttClient message: ', msg)

      let action_type = msg?.action_type

      switch (action_type) {
        case 'new_notification':
          notificationQuery.refetch()

          try {
            CefSharp.BindObjectAsync('formNotificationLauncher').then(() => {
              return formNotificationLauncher.playNotificationSound('savanna').then((actualResult) => actualResult)
            })
          } catch (e) {

          }

          return
        default:
          return
      }
    })

    return true
  }

  return (
    <React.Fragment>
      <AppBar
        style={style.header}
        titleStyle={{ lineHeight: '50px' }}
        title={
          <React.Fragment>
            {call_state.call_notifications_token && call_state.server_url && liveNotificationsKeyQuery?.data?.room_name &&
              <IntercomNotifications
                channel_name={liveNotificationsKeyQuery.data.room_name}
              />}
            <ChatButtonMenu/>
            <IntercomHeader/>

            <Popup
              flowing hoverable on="click" position="bottom center" verticalOffset={18}
              trigger={
                <Label size="large" color="green" style={style.tags}>
                  Suporte
                </Label>
              }
            >
              <h3>Via WhatsApp: 62 3095-3020</h3>
            </Popup>

            {settings?.guarita__status === '1' &&
              <React.Fragment>
                &nbsp;
                <Label size="large" color={guarita_status === true ? 'blue' : 'red'} style={style.tags}>
                  Guarita {guarita_status === true ? 'Conectado' : 'Desconectado'}
                </Label>
              </React.Fragment>}

            <ServerServicePopup/>

            {/*<Popup flowing hoverable on="click" position="bottom center" verticalOffset={18}*/}
            {/*       style={{ overflowY: 'scroll', maxHeight: '450px' }}*/}
            {/*       trigger={*/}
            {/*         <Label size="large" color="blue" style={style.tags}>*/}
            {/*           Visitas {visits.length}*/}
            {/*         </Label>*/}
            {/*       }>*/}

            {/*  <Button type="button" color="red" onClick={() => localPostApi('access-manager/finish_all_visits')}>*/}
            {/*    Finalizar todas visitas*/}
            {/*  </Button>*/}

            {/*  <Table basic="very" padded>*/}
            {/*    <Table.Header>*/}
            {/*      <Table.Row>*/}
            {/*        <Table.HeaderCell>Nome Visitante</Table.HeaderCell>*/}
            {/*        <Table.HeaderCell>Destino</Table.HeaderCell>*/}
            {/*        <Table.HeaderCell>Início da Validade</Table.HeaderCell>*/}
            {/*        <Table.HeaderCell>Válido até</Table.HeaderCell>*/}
            {/*        <Table.HeaderCell></Table.HeaderCell>*/}
            {/*      </Table.Row>*/}
            {/*    </Table.Header>*/}

            {/*    <Table.Body>*/}
            {/*      {visits.map(item => (*/}
            {/*        <Table.Row key={item.start_date}>*/}
            {/*          <Table.Cell>{item.name}</Table.Cell>*/}
            {/*          <Table.Cell>{item.destination}</Table.Cell>*/}
            {/*          <Table.Cell>{moment(item.start_date).format('DD/MM/YY HH:mm')}</Table.Cell>*/}
            {/*          <Table.Cell>{moment(item.end_date).format('DD/MM/YY HH:mm')}</Table.Cell>*/}
            {/*          <Table.Cell>*/}
            {/*            <Button color={'red'} icon basic size="mini" floated="right" type="button"*/}
            {/*                    onClick={() =>*/}
            {/*                      localPostApi('access-manager/finish_visit_by_id', { visit_id: item.id })*/}
            {/*                    }>*/}
            {/*              <Icon name="trash"/>*/}
            {/*            </Button>*/}
            {/*          </Table.Cell>*/}
            {/*        </Table.Row>*/}
            {/*      ))}*/}
            {/*    </Table.Body>*/}
            {/*  </Table>*/}
            {/*</Popup>*/}

            <Popup flowing hoverable on="click" position="bottom center" verticalOffset={18}
                   style={{ overflowY: 'scroll', maxHeight: '600px', minWidth: '300px' }}
                   trigger={
                     <Label size="large" color="blue" style={style.tags}>
                       Botoeira
                     </Label>
                   }>
              <AccessButtonTable/>
            </Popup>


            <Popup flowing hoverable on="click" position="top center" verticalOffset={18}
                   style={{ overflowY: 'scroll', maxHeight: '90%', minWidth: '99%' }}
                   trigger={
                     <Label size="large" color="blue" style={style.tags}>
                       Monitoramento
                     </Label>
                   }>
              <LiveTable/>
            </Popup>

          </React.Fragment>
        }
        iconElementLeft={<div/>}
        iconElementRight={
          <div style={style.iconsRightContainer}>
            <IconMenu
              color={white}
              targetOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
              maxHeight={400}
              onClick={notificationQuery.refetch}
              iconButtonElement={
                <Badge
                  badgeContent={''} primary={true}
                  style={{ padding: '7px 10px 10px 14px' }}
                  badgeStyle={{
                    width: '14px',
                    height: '14px',
                    backgroundColor: notificationQuery.data?.unread_count ? 'red' : 'transparent'
                  }}
                >
                  <NotificationsIcon color={white}/>
                </Badge>
              }
              style={{ marginRight: 5 }}
            >
              <MenuItem secondaryText="Marcar tudo como lido" onClick={notificationReadMeQuery.refetch}/>
              <Divider/>

              {notificationQuery.isLoading && <h2>Carregando...</h2>}

              {!notificationQuery.isLoading && Array.isArray(notificationQuery.data?.results) && notificationQuery.data.results.map((i: any) => (
                <ListItem
                  key={i.id}
                  primaryText={<React.Fragment>{i.unread && <Label circular color={'red'} empty/>} {i.subject}</React.Fragment>}
                  secondaryText={moment(i.date_sent).fromNow()}
                  onClick={() => notificationHandler(i)}
                />
              ))}
            </IconMenu>

            <IconMenu color={white} iconButtonElement={<IconButton><MoreVertIcon color={white}/></IconButton>}
                      targetOrigin={{ horizontal: 'right', vertical: 'top' }}
                      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
              {/*<MenuItem primaryText="Config" containerElement={<Link to="/configs"/>}/>*/}
              <div style={{ margin: 15 }}>
                <p>Porteiro: <b>{me?.name}</b></p>
                <p>Condomínio: <b>{me?.team?.name}</b></p>
              </div>

              <MenuItem primaryText="Sair" containerElement={<a href="/login"/>} leftIcon={<ExitToApp/>}/>
            </IconMenu>
          </div>
        }
      />

      <UserWebsocketHandler/>
      <LicenseHeader/>
    </React.Fragment>
  )
}

export default React.memo(Header)

