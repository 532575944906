import React, { useEffect, useState } from 'react'
import useLicenseStore from '../redux/store/useLicenseStore'
import LicenseService from '../services/LicenseService'

export const LicenseHeader = () => {
  const data = useLicenseStore(state => state.ci_license)

  const [_d, _setValue] = useState(0)

  let machine_uuid = ''

  if (data?.machine_uuid) {
    machine_uuid = data.machine_uuid.slice(0, 5)
  }

  useEffect(() => {
    LicenseService.refreshVerification(true).then(res => { }).catch(res => { })
  }, [])

  return (
    <React.Fragment>
      {data && data?.status !== null && data?.status !== 1 &&
        <div
          style={{
            paddingTop: 75,
            paddingBottom: 20,
            paddingLeft: 205,
            background: '#f69191',
            width: '100%'
          }}
        >
          <p style={
            {
              fontSize: '18px',
              fontWeight: 'bold',
              color: '#1c0000'
            }}>
            A licença não está ativa e você poderá perder o acesso. Procure o suporte para corrigir! {machine_uuid}
          </p>
        </div>}
    </React.Fragment>
  )
}
