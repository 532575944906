import React, { useEffect } from 'react'
import { useChannel, useEvent } from '@harelpls/use-pusher'
import { push_toast } from '../utils/common'
import { useMeQuery } from '../hooks'

const Handler = ({ user }: { user: any }) => {
  const channel_name = `user___${user.id}`
  const channel = useChannel(channel_name)

  useEvent<any>(channel, 'message', (arr) => {
    if (arr?.event_name === 'send_toast') {
      if (user?.role === 3) {
        return
      }

      push_toast(arr.message, arr?.type)

      return
    }
  })

  useEffect(() => {
    return () => {
      if (channel) {
        channel.disconnect()
        console.log('Disconnected from User Channel', channel_name)
      }
    }
  }, [])

  return (<></>)
}

export const UserWebsocketHandler = () => {
  const { data: me } = useMeQuery()

  if (me?.id) {
    return <Handler user={me}/>
  }

  return null
}