import 'ag-grid-enterprise'
import { Formik } from 'formik'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Form, Grid, Header, Image, Message, Modal } from 'semantic-ui-react'
import swal from 'sweetalert2'
import { FF, FormikInput, FormikSelect } from '../../components/form/FormInputs'
import UnitsSelect from '../../components/form/UnitsSelect'
import ActivityLoading from '../../components/Loading'
import { WebcamCaptureUploader } from '../../components/WebcamCapture'
import { MAILING_STATUS } from '../../fields'
import ToasterService from '../../services/ToasterService'
import { fetchApi, putApi, putApiCustom } from '../../utils/api'
import { getDynamicEnumValue, getRemoteConfig, isRequired } from '../../utils/form'
import MailingGrid from './MailingGrid'
import { useQuery } from 'react-query'
import { qs_local_server } from '../../queries-local-server'

const PickMailingModal = (props) => {
  const { data: last_events } = useQuery('getLastEvents', () => qs_local_server.events.lastEvents())

  const [waiting_facial, setWaitingFacial] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {

    processEvents()

  }, [last_events])

  const processEvents = async () => {
    console.log('MAILING: last_events', last_events)
    console.log('MAILING: props.current_mailing', props.current_mailing)
    console.log('MAILING: props.is_bulk', props.is_bulk)

    if (waiting_facial & Array.isArray(last_events) && last_events.length > 0) {

      if (!props.is_bulk && !props.current_mailing?.unit_id) {
        ToasterService.warning('Sem unidade definida na correspondência!')
        setWaitingFacial(false)
      } else {
        const noww = moment().subtract(10, 'seconds')
        let first_mailing = null

        if (props.is_bulk) {
          first_mailing = props.selected_rows[0]
        } else {
          first_mailing = props.current_mailing
        }

        console.log('MAILING: first_mailing', first_mailing)

        const ok_events = last_events.slice(0, 10).filter(e => {
          let evt_date = moment(e.date).isAfter(noww)
          let all_units_id_fixed = e?.all_unit_ids?.map(a => String(a))

          console.log('MAILING: all_units_id_fixed', all_units_id_fixed)

          return evt_date && all_units_id_fixed.indexOf(String(first_mailing?.unit_id)) > -1
        })

        console.log('MAILING: ok_events', ok_events)

        if (ok_events.length > 0) {
          try {
            const evt = ok_events[0]
            console.log('MAILING: evt', evt)

            setLoading(true)

            if (props.is_bulk) {
              const payload = {
                picked: evt.name,
                status: 2,
                mailing_ids: props.selected_rows.map(row => row.id)
              }

              await putApiCustom('admin/mailings/bulk_delivery', payload)
            } else {
              const payload = {
                picked: evt.name,
                // doc: '',
                status: 2
                // files_attach: []
              }

              await putApi('admin/mailings', payload, props.current_mailing.id)
            }

            swal({ type: 'success', title: 'Corrêspondencia retirada!' })

            props.handleClose()
            props.mailingGridApi.purgeServerSideCache()
            props.mailingGridApi.deselectAll()
            setLoading(false)

          } catch (err) {
            ToasterService.sendErrors(err)

            setLoading(false)
          }
        }
      }
    }

  }

  return (
    <Modal open={true}>
      <Formik
        initialValues={
          props.is_bulk ?
            {
              picked: '',
              doc: '',
              delivery_place: '',
              files_attach: []
            } :
            {
              unit: props.current_mailing?.unit_id,
              picked: '',
              doc: '',
              delivery_place: '',
              status: 2,
              files_attach: []
            }
        }
        validate={values => {
          let errors = {}
          if (!props.is_bulk && !values.unit) errors.unit = 'Seleciona a unidade'

          if (!values.picked) errors.picked = 'Digite o nome'

          if (isRequired('mailing__doc')) {
            if (!values.doc) errors.doc = 'Digite o documento'
          }

          if (isRequired('mailing__delivery_place')) {
            if (!values.delivery_place) errors.delivery_place = 'Selecione uma opção'
          }

          return errors
        }}
        onSubmit={(values) => {
          setLoading(true)

          if (props.is_bulk) {
            const payload = {
              ...values,
              mailing_ids: props.selected_rows.map(row => row.id)
            }

            putApiCustom('admin/mailings/bulk_delivery', payload).then((res) => {
              swal({ type: 'success', title: 'Corrêspondencia retiradas!' })
              props.handleClose()

              props.mailingGridApi.purgeServerSideCache()
              props.mailingGridApi.deselectAll()

              setLoading(false)
            }).catch((err) => {
              setLoading(false)
              ToasterService.sendErrors(err)
            })

          } else {
            putApi('admin/mailings', values, props.current_mailing.id).then((res) => {
              swal({ type: 'success', title: 'Corrêspondencia retirada!' })
              props.handleClose()

              props.mailingGridApi.purgeServerSideCache()

              setLoading(false)
            }).catch((err) => {
              setLoading(false)
              ToasterService.sendErrors(err)
            })
          }

        }}
        render={({ values, setFieldValue, submitForm }) => (
          <>
            <Modal.Header>
              {props.is_bulk ?
                'Retirar correspondências em massa' :
                'Retirar Correspondência'}
            </Modal.Header>
            <Modal.Content scrolling>
              <Form className="ui form large error">
                {!props.is_bulk && !props.current_mailing.unit_id && <UnitsSelect name="unit" label={'Selecione a Unidade'}/>}

                <FF label="Nome" name="picked" component={FormikInput}/>
                <FF label="Documento" name="doc" component={FormikInput}/>

                {isRequired('mailing__delivery_place') &&
                  <FF
                    label={'Local de Entrega'} name="delivery_place" component={FormikSelect}
                    local_options={getRemoteConfig('general.mailing__delivery_place__enum') ?? {}}
                  />}

                <WebcamCaptureUploader values={values} setFieldValue={setFieldValue} size="medium"/>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button
                onClick={() => {
                  if (!props.is_bulk && !props.current_mailing?.unit_id) {
                    ToasterService.warning('Sem unidade definida na correspondência!')
                    setWaitingFacial(false)
                  } else {
                    setWaitingFacial(true)
                  }
                }}
                disabled={waiting_facial}
                loading={waiting_facial}
              >
                {waiting_facial ? 'Aguardando facial...' : 'Retirar com Facial'}
              </Button>
              <button className="ui red button" onClick={props.handleClose}>Cancelar</button>
              <button className="ui button primary" onClick={submitForm}>Enviar</button>
            </Modal.Actions>
          </>
        )}
      />


      <ActivityLoading visible={loading}/>
    </Modal>

  )
}

class MailingListPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      current_mailing: null,
      loading: false,
      open_modal: false,
      details_modal: false,
      by_code_modal: false,
      change_unit_modal: false,
      selected_rows: [],
      open_batch_modal: false
    }
  }

  handleClose = () => {
    this.setState({
      open_modal: false,
      open_batch_modal: false,
      details_modal: false,
      by_code_modal: false,
      change_unit_modal: false,
      current_mailing: null
    })

    this.props.mailingGridApi.deselectAll()
  }

  handleOpen = (data) => {
    this.setState({ open_modal: true, current_mailing: data })
    this.props.mailingGridApi.deselectAll()
  }

  handleOpenBatch = (data) => {
    console.log('this.state.selected_rows', this.state.selected_rows)

    let unit_ids = this.state.selected_rows.map(row => row.unit_id)
    let unique_unit_ids = Array.from(new Set(unit_ids))
    if (unique_unit_ids.length !== 1) {
      swal({
        title: 'Todas as correspondências selecionadas devem ser da mesma unidade!',
        type: 'error'
      })

      return
    }

    if (!unique_unit_ids[0]) {
      swal({
        title: 'Unidade não definida na correspondência!',
        type: 'error'
      })
      return
    }

    this.setState({ open_batch_modal: true })
  }

  handleOpenDetails = (data) => {
    this.setState({ details_modal: true, current_mailing: data })
    this.props.mailingGridApi.deselectAll()
  }

  handleByCode = () => {
    this.setState({ by_code_modal: true, current_mailing: null })
    this.props.mailingGridApi.deselectAll()
  }

  handleOpenChangeUnit = (data) => {
    this.setState({ change_unit_modal: true, current_mailing: data })
    this.props.mailingGridApi.deselectAll()
  }

  render() {
    return (
      <div>
        <h1 className="ui huge header">
          Correpondências

          &nbsp;
          &nbsp;
          <Link to="/admin/mailing-form">
            <Button primary size="small" content="Novo" icon="plus"/>
          </Link>

          {Array.isArray(this.state.selected_rows) && this.state.selected_rows.length > 0 &&
            <Button color="green" size="small" content="Retirar em massa" icon="upload" onClick={this.handleOpenBatch}/>}

          <Button color="green" size="small" content="Retirar por código" icon="upload" onClick={this.handleByCode}/>
        </h1>

        <div className="ui divider hidden"/>

        <MailingGrid componentParent={this}/>

        {this.state.open_modal && <PickMailingModal
          current_mailing={this.state.current_mailing}
          handleClose={this.handleClose}
          mailingGridApi={this.props.mailingGridApi}
        />}

        {this.state.open_batch_modal && <PickMailingModal
          current_mailing={this.state.current_mailing}
          handleClose={this.handleClose}
          mailingGridApi={this.props.mailingGridApi}
          is_bulk={true}
          selected_rows={this.state.selected_rows}
        />}


        {this.state.change_unit_modal &&
          <Modal open={this.state.change_unit_modal}>
            <Modal.Header>Alterar Unidade</Modal.Header>
            <Modal.Content>
              <Formik
                ref={node => (this.form = node)}
                initialValues={{
                  unit: ''
                }}
                onSubmit={(values) => {
                  this.setState({ loading: true })

                  putApi('admin/mailings', values, this.state.current_mailing.id).then((res) => {
                    swal({ type: 'success', title: 'Corrêspondencia salva!' })

                    this.handleClose()
                    this.props.mailingGridApi.purgeServerSideCache()

                    this.setState({ loading: false })
                  }).catch((err) => {
                    this.setState({ loading: false })
                    ToasterService.sendErrors(err)
                  })
                }}
                render={({ submitForm }) => (
                  <Form className="ui form large error">
                    <UnitsSelect/>

                    <button className="ui button primary right floated large" onClick={submitForm}>Enviar</button>
                    <button className="ui red button right floated large" onClick={this.handleClose}>Cancelar</button>

                    <div className="ui hidden divider"/>
                    <div className="ui hidden divider"/>
                  </Form>
                )}
              />
            </Modal.Content>
          </Modal>}

        {this.state.by_code_modal &&
          <Modal open={this.state.by_code_modal}>
            <Modal.Header>Retirar por código</Modal.Header>
            <Modal.Content>
              <Formik
                initialValues={{
                  code: ''
                }}
                onSubmit={(values) => {
                  this.setState({ loading: true })

                  fetchApi(`admin/mailings?filter{status}=1&filter{obj_number}=${values.code}`).then((res) => {
                    let _res_data = res?.data

                    this.setState({ loading: false, by_code_modal: false })

                    if (Array.isArray(_res_data) && _res_data.length > 0) {
                      this.handleOpen(_res_data[0])
                    } else {
                      this.handleClose()
                      swal({ type: 'error', title: 'Nenhuma correspondência disponível para retirar com o código informado!' })
                    }

                  }).catch((err) => {
                    this.setState({ loading: false })
                    ToasterService.sendErrors(err)
                  })
                }}
                render={({ submitForm }) => (
                  <Form className="ui form large error">
                    <Message info icon="info" header="O código é exibido dentro do app dos moradores na tela de correspondências." size="tiny"/>

                    <FF label="Código" name="code" component={FormikInput}/>

                    <button className="ui button primary right floated large" onClick={submitForm}>Enviar</button>
                    <button className="ui red button right floated large" onClick={this.handleClose}>Cancelar</button>

                    <div className="ui hidden divider"/>
                    <div className="ui hidden divider"/>
                  </Form>
                )}
              />
            </Modal.Content>
          </Modal>}

        {this.state.details_modal &&
          <Modal open={this.state.details_modal}>
            <Modal.Header>Detalhes Correspondência</Modal.Header>
            <Modal.Content>
              <Grid columns={'equal'}>
                <Grid.Row>
                  <Grid.Column>
                    <Header sub>Unidade:</Header>
                    <span>{this.state.current_mailing?.unit?.with_block}</span>
                  </Grid.Column>
                  <Grid.Column>
                    <Header sub>Remetente:</Header>
                    <span>{this.state.current_mailing?.from_field}</span>
                  </Grid.Column>
                  <Grid.Column>
                    <Header sub>Destinatário:</Header>
                    <span>{this.state.current_mailing?.to}</span>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column>
                    <Header sub>Status:</Header>
                    <span>{MAILING_STATUS[this.state.current_mailing?.status]}</span>
                  </Grid.Column>
                  <Grid.Column>
                    <Header sub>Data de Criação:</Header>
                    <span>{moment(this.state.current_mailing?.created_at).format('DD/MM/YY HH:mm')}</span>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column>
                    <Header sub>Retirado por:</Header>
                    <span>{this.state.current_mailing?.picked}</span>
                  </Grid.Column>
                  <Grid.Column>
                    <Header sub>Retirado em:</Header>
                    <span>
                      {this.state.current_mailing?.delivered_at &&
                        moment(this.state.current_mailing?.delivered_at).format('DD/MM/YY HH:mm')}
                    </span>
                  </Grid.Column>
                  <Grid.Column>
                    <Header sub>Local de Entrega:</Header>
                    <span>
                      {getDynamicEnumValue(this.state.current_mailing?.delivery_place, 'general.mailing__delivery_place__enum')}
                    </span>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column>
                    <Header sub>Descrição:</Header>
                    <span>{this.state.current_mailing?.description}</span>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column>
                    <Header sub>Fotos:</Header>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  {
                    this.state.current_mailing?.files.map((file) => (
                      <Image size={'medium'} rounded src={file.file} style={{ margin: '8px' }}/>
                    ))
                  }
                </Grid.Row>
              </Grid>
            </Modal.Content>
            <Modal.Actions>
              <button className="ui red button large" onClick={this.handleClose}>Fechar</button>
            </Modal.Actions>
          </Modal>}

        <ActivityLoading visible={this.state.loading}/>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    mailingGridApi: state.grid_refs.mailing
  }
}

export default connect(mapStateToProps)(MailingListPage)

